import { orderStyleFormatter } from '../../app/modules/shared/util/orderStyleFormatter';

const SET_CUTTING_ORDERS = 'SET_CUTTING_ORDERS';
const SET_CUTTING_ORDER = 'SET_CUTTING_ORDER';
export const GET_CUTTING_ORDER = 'GET_CUTTING_ORDER';
const SET_IS_LOADING = 'SET_IS_LOADING';

const initialState = {
    cuttingOrders: [],
    cuttingOrder: null,
    isLoading: true,
};
//Selectors
export const cuttingOrdersSelector = (state) =>
    state.cuttingOrder.cuttingOrders;
export const cuttingOrderSelector = (state) => state.cuttingOrder.cuttingOrder;
export const isLoadingSelector = (state) => state.cuttingOrder.isLoading;

//Action creators == dispatch actions
export const setCuttingOrders = (data) => ({
    type: SET_CUTTING_ORDERS,
    payload: data,
});

export const setCuttingOrder = (cuttingOrder, processCO) => ({
    type: SET_CUTTING_ORDER,
    payload: {
        cuttingOrder,
        processCO,
    },
});

export const getCuttingOrder = (payload) => ({
    type: GET_CUTTING_ORDER,
    payload,
});

export const setIsLoading = (payload) => ({
    type: SET_IS_LOADING,
    payload,
});

export const CuttingOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CUTTING_ORDERS: {
            return { ...state, cuttingOrders: action.payload };
        }
        case GET_CUTTING_ORDER: {
            return { ...state, isLoading: true };
        }
        case SET_CUTTING_ORDER: {
            const { cuttingOrder, processCO = true } = action.payload;
            if (cuttingOrder && processCO) {
                // Ensure productionOrder is always an array
                if (
                    cuttingOrder.productionOrders &&
                    !Array.isArray(cuttingOrder.productionOrders)
                ) {
                    cuttingOrder.productionOrders = [
                        cuttingOrder.productionOrders,
                    ];
                }

                // Process each production order in the array
                const productionOrders = cuttingOrder.productionOrders.map(
                    (order) => {
                        const poOrderStyles =
                            order.orderStyles || order.order_styles;
                        const {
                            formattedData,
                            sizeQuantities,
                            poSizes,
                            poColors,
                        } = orderStyleFormatter(poOrderStyles);
                        const sortedSizes = Object.values(poSizes).sort(
                            (a, b) => a.order - b.order,
                        );
                        return {
                            ...order,
                            orderNumber:
                                order.order_number ?? order.orderNumber,
                            poSizes,
                            poColors,
                            sortedSizes,
                            poFormattedStyles: {
                                formattedData,
                                sizeQuantities,
                            },
                        };
                    },
                );
                return {
                    ...state,
                    cuttingOrder: {
                        ...cuttingOrder,
                        productionOrders,
                        canAddBatches:
                            cuttingOrder.marker.isMainFabric &&
                            !cuttingOrder.bundledQuantity,
                        canEdit: !cuttingOrder.bundledQuantity,
                    },
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    cuttingOrder: cuttingOrder
                        ? {
                              ...state.cuttingOrder,
                              ...cuttingOrder,
                          }
                        : null,
                };
            }
        }
        case SET_IS_LOADING: {
            return { ...state, isLoading: action.payload };
        }
        default:
            return state;
    }
};
