const SET_SERIALIZE_RULE = 'SET_SERIALIZE_RULES';
const SET_SERIALIZE_TYPE = 'SET_SERIALIZE_TYPE';
const SET_SERIAL_CHANGED = 'SET_SERIAL_CHANGED';

const SERIALIZE_RULES_KEY = 'serializeRules';

const INITIAL_RULES = {
    cuttingOrder: false,
    rollNumber: false,
    size: false,
    color: false,
    infinite: true,
    productionOrder: false,
};

const getSavedRules = () => {
    const savedRules = JSON.parse(
        localStorage.getItem(SERIALIZE_RULES_KEY) || '{}'
    );

    return savedRules.infinite !== undefined
        ? savedRules
        : INITIAL_RULES;
};

const initialState = {
    serializeType: '',
    rules: getSavedRules(),
    serialChanged: false,
};

/**
 * @param name {string}
 * @param checked {boolean}
 * @returns {{type: string, payload: {name: string, checked: boolean}}}
 */
export const setSerializeRule = (name, checked) => ({
    type: SET_SERIALIZE_RULE,
    payload: { name, checked },
});

/**
 * @param {'all' | 'selected'} type
 * @returns {{type: string}}
 */
export const setSerializeType = (type) => ({
    type: SET_SERIALIZE_TYPE,
    payload: type,
});

export const setSerialChanged = (changed) => ({
    type: SET_SERIAL_CHANGED,
    payload: changed,
});

export const BatchesSerializingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SERIALIZE_RULE: {
            const { name, checked } = action.payload;

            let rules = {};
            if (checked && name === 'infinite') {
                rules = INITIAL_RULES;
            } else {
                rules = {
                    ...state.rules,
                    infinite: false,
                    [name]: checked,
                };

                if (Object.values(rules).indexOf(true) === -1)
                    rules = {
                        ...rules,
                        infinite: true,
                    };
            }

            localStorage.setItem(SERIALIZE_RULES_KEY, JSON.stringify(rules));
            return { ...state, rules };
        }
        case SET_SERIALIZE_TYPE:
            return { ...state, serializeType: action.payload };
        case SET_SERIAL_CHANGED:
            return {
                ...state,
                serialChanged: action.payload,
            };
        default:
            return state;
    }
};
