// Action type for setting bundling rules
const SET_BUNDLING_RULES = 'SET_BUNDLING_RULES';

//? Key used to store bundling rules in local storage
const BUNDLING_RULES_KEY = 'bundlingRules';

// Selector to get bundling rules from the Redux state
export const bundlingRulesSelector = (state) => state.batchesBundling.rules;

// Function to get saved bundling rules from local storage
const getSavedRules = () => {
    //? Retrieve saved rules from local storage and parse them as JSON
    const savedRules = JSON.parse(
        localStorage.getItem(BUNDLING_RULES_KEY) || '{}'
    );

    // Return saved rules if they exist, otherwise return default rules
    return savedRules.roll !== undefined
        ? savedRules
        : {
              roll: false, // Default value for roll bundling rule
              colorShade: false, // Default value for color shade bundling rule
              quantity: false, // Default value for quantity bundling rule
              separateSizes: false, // Default value for separate sizes bundling rule
              typical: 0, // Default value for typical quantity
              maximum: 0, // Default value for maximum quantity
          };
};

// Initial state for the bundling rules from the above function
const initialState = {
    rules: getSavedRules(),
    bundlingChanged: false,
};

export const BatchesBundlingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUNDLING_RULES: {
            const target = action.payload;
            const rules = {
                ...state.rules,
                [target.name]: parseInt(target.value) || target.checked,
            };

            localStorage.setItem(BUNDLING_RULES_KEY, JSON.stringify(rules));
            return { ...state, rules };
        }
        default:
            return state;
    }
};

// Action creator to set bundling rules used with dispatch
export const setBundlingRules = (rule) => ({
    type: SET_BUNDLING_RULES,
    payload: rule,
});

