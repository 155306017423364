import { store } from '../../../../store';
import { useSelector } from 'react-redux';

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const allowPieceTracking = () => {
    try {
        return !!JSON.parse(localStorage.user_id).factoryData
            .allowPieceTracking;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const isPieceTrackingAllowed = () => {
    try {
        const state = store.getState();
        return (
            state.settings.allowPieceTracking
        );
    } catch (e) {
        return false;
    }
};

/** @returns {boolean}  */
export const usePieceTracking = () =>
    useSelector(({ settings }) => settings.allowPieceTracking);
