const getCssVariable = (variable) =>
    getComputedStyle(document.documentElement).getPropertyValue(variable);

const theme = {
    primaryColor: getCssVariable('--primaryColor'),
    secondaryColor: getCssVariable('--secondaryColor'),
    successColor: getCssVariable('--successColor'),
    warningColor: getCssVariable('--warningColor'),
    dangerColor: getCssVariable('--dangerColor'),
    spacingUnit: getCssVariable('--spacingUnit'),
    lightColor: getCssVariable('--lightColor'),
    mediumGray: getCssVariable('--mediumGray'),
    darkerColor: getCssVariable('--darkerColor'),
    whiteColor: getCssVariable('--whiteColor'),
    customLightColor: getCssVariable('--customLightColor'),
    infoColor: getCssVariable('--infoColor'),
    goldenColor: getCssVariable('--goldenColor'),
    darkSecondary: getCssVariable('--darkSecondary'),
    darkGray: getCssVariable('--darkGray'),
    lgBreakpoint: getCssVariable('--lgBreakpoint'),
    geekblue: getCssVariable('--geekblue'),
    blackColor: getCssVariable('--blackColor'),
    redColor: getCssVariable('--redColor'),
    defaultBlue: getCssVariable('--defaultBlue'),
    systemDefaultSuccessColor: getCssVariable('--systemDefaultSuccessColor'),
    systemDefaultErrorColor: getCssVariable('--systemDefaultErrorColor'),
    dangerRedColor: getCssVariable('--dangerRedColor'),
    alertYellowColor: getCssVariable('--alertYellowColor'),
    successGreenColor: getCssVariable('--successGreenColor'),
};

export default theme;
