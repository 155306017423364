export const markerFormatter = (marker) => ({
    ...marker,
    totalPieces: marker.styles.reduce(
        (total, style) =>
            total +
            style.sizesCount.reduce(
                (styleTotal, size) => styleTotal + size.count,
                0,
            ),
        0,
    ),
    toleranceP: ((marker.tolerance / marker.length) * 100).toFixed(0),
    sizes: marker.styles.flatMap((style) =>
        style.sizesCount.map((item) => item.size.name),
    ),
});
