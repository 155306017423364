// Function to format order styles into a structured object with colors, sizes and quantities

import { AddEditProductionOrderAPI } from 'app/modules/Production/Order/AddEditProductionOrder/AddEditProductionOrderAPI';
import { useAuthuser } from '../hooks/useAuthuser';
import { apiRequest } from '../api/apiRequest';

// const company = 'glass';
// const factory = 1;

//^ done in Co Reducer
export const orderStyleFormatter = (orderStyles) => {
    // const { company, factory } = useAuthuser();
    // console.log('orderStyles', orderStyles[0].order);

    // let { data } = await apiRequest(
    //     AddEditProductionOrderAPI.getProductionOrder,
    //     company,
    //     factory,
    //     orderStyles[0].order,
    // );
    // let order = data.order;
    // console.log('order', order);

    // let pOrderNumber = order.orderNumber || order.order_number;
    let pOrderNumber;
    const formattedData = {};
    const sizeQuantities = {};
    const poSizes = {};
    const poColors = {};

    orderStyles.forEach((item) => {
        pOrderNumber = item.order.order_number;
        // if (item.order.toString() !== order._id.toString()) {
        //     order = await AddEditProductionOrderAPI.getProductionOrder(
        //         company,
        //         factory,
        //         item.order.toString(),
        //     );
        //     pOrderNumber = order.orderNumber || order.order_number;
        // }
        // Create unique keys by concatenating orderStyleId
        const uniqueColorId = `${pOrderNumber}_${item.color._id}`;
        const uniqueSizeId = `${pOrderNumber}_${item.size._id}`;

        // Format data with unique color key
        formattedData[uniqueColorId] = {
            ...formattedData[uniqueColorId],
            ...item.color,
            quantity:
                (formattedData[uniqueColorId]?.quantity || 0) + item.quantity,
            sizes: {
                ...formattedData[uniqueColorId]?.sizes,
                [uniqueSizeId]: {
                    ...item.size,
                    quantity: item.quantity,
                },
            },
        };

        // Use unique size ID for quantities
        sizeQuantities[uniqueSizeId] =
            (sizeQuantities[uniqueSizeId] || 0) + item.quantity;

        // Store sizes with unique IDs
        if (!poSizes[uniqueSizeId]) {
            poSizes[uniqueSizeId] = {
                ...item.size,
                _id: uniqueSizeId,
            };
        }

        // Store colors with unique IDs
        if (!poColors[uniqueColorId]) {
            poColors[uniqueColorId] = {
                ...item.color,
            };
        }
    });

    return {
        formattedData,
        sizeQuantities,
        poSizes,
        poColors,
    };
};

export const poDataColorsSizes = (
    orderStyles,
    orderStylesProperty = 'order_styles',
) => {
    if (!orderStyles) return;

    const { formattedData, sizeQuantities, poColors, poSizes } =
        orderStyleFormatter(orderStyles[orderStylesProperty]);

    const poData = {
        formattedData,
        sizeQuantities,
        ...orderStyles,
    };

    const sizesArray = Object.values(poSizes);
    const colorsArray = Object.keys(poColors).map((colorId) => ({
        _id: colorId,
        name: poColors[colorId].name,
    }));

    return { poData, sizesArray, colorsArray };
};
