import { useAuthuser } from 'app/modules/shared/hooks/useAuthuser';
import showNotificationMessage from 'app/modules/shared/util/NotificationMessage';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setSignUpUserSuccess } from 'reducers/Auth';
import {
    setAllowPieceTracking,
    setFactoryAQL,
    setFactoryAutomateBeats,
    setFactoryBatchGrouping,
    setFactoryCutting,
    setFactoryIdleTime,
    setFactoryTimezone,
} from 'reducers/Settings';
import IntlMessages from 'util/IntlMessages';

const AuthUserWrapper = ({ children }) => {
    const { factoryId } = useParams();
    const authUser = useAuthuser();
    const dispatch = useDispatch();

    useEffect(() => {
        if (factoryId === 'admin' || !factoryId || !authUser || !dispatch)
            return;

        const factoryData = authUser?.factories?.find(
            (factory) => factory.factory_company_id === factoryId,
        );

        if (!factoryData) {
            showNotificationMessage(
                'error',
                <IntlMessages id='shared.error.EF005' />,
            );
            return;
        }

        const newUser = {
            ...authUser,
            factory: factoryId,
            factoryData,
        };

        // set user data
        dispatch(setSignUpUserSuccess(JSON.stringify(newUser)));

        // set factory data
        dispatch(setFactoryIdleTime(factoryData?.idleTime));
        dispatch(setFactoryBatchGrouping(factoryData?.batchGrouping));
        dispatch(setAllowPieceTracking(factoryData?.allowPieceTracking));
        dispatch(setFactoryCutting(factoryData?.isCuttingEnabled));
        dispatch(setFactoryAutomateBeats(factoryData?.isAutomateBeats));
        dispatch(setFactoryTimezone(factoryData?.timeZone));
        dispatch(setFactoryAQL(factoryData));
    }, [dispatch, factoryId, authUser]);

    return <>{children}</>;
};

export default AuthUserWrapper;
