import axiosInstance from '../../shared/api/axiosInstance';

const AddEditCuttingOrderAPI = {
    addCuttingOrder: (company, factory, cuttingOrderData) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/addCuttingOrder`,
            {
                ...cuttingOrderData,
            },
        ),

    editCuttingOrder: (company, factory, cuttingOrderData) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/editCuttingOrder`,
            {
                ...cuttingOrderData,
            },
        ),
    //*rezmi update------------
    getMarkersByPOs: (company, factory, productionOrderIds) => {
        // console.log(productionOrderIds);
        // Check if productionOrderIds is an array and construct the query parameters
        const productionOrderIdsQuery = Array.isArray(productionOrderIds)
            ? productionOrderIds
                  .map((id) => `productionOrderId=${id.value.toString()}`)
                  .join('&')
            : `productionOrderId=${productionOrderIds}`;
        return axiosInstance.get(
            `${company}/${factory}/markers?${productionOrderIdsQuery}`,
        );
    },

    getRollsByPoClient: (
        company,
        factory,
        productionOrderIds,
        cuttingOrderId,
    ) => {
        // console.log('productionOrderId:::::::>', productionOrderIds);
        // Convert array to comma-separated string if array
        const orderIds = Array.isArray(productionOrderIds)
            ? productionOrderIds.map((po) => po._id).join(',')
            : productionOrderIds;
        return axiosInstance.get(
            `${company}/${factory}/rolls/getRollsForPoClient?productionOrderId=${orderIds}${
                cuttingOrderId ? '&cuttingOrderId=' + cuttingOrderId : ''
            }`,
        );
    },

    getCuttingOrder: (company, factory, cuttingOrderId) =>
        axiosInstance.get(
            `${company}/${factory}/cuttingOrders/${cuttingOrderId}`,
        ),

    getEstimatedQuantityData: (company, factory, productionOrder) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/estimatedCutData`,
            {
                productionOrder,
            },
        ),

    addAutomaticBatches: (company, factory, cuttingOrderId, batches) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/${cuttingOrderId}/batches`,
            {
                batches,
            },
        ),

    getNextCuttingOrderNumber: (company, factory, productionOrderIds) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/nextCuttingOrder`,
            {
                productionOrderIds,
            },
        ),

    getCuttingEnabledPOs: (company, factory, statuses) =>
        axiosInstance.post(
            `${company}/${factory}/orders/listEnabledCOsProductionOrders`,
            { statuses },
        ),
};

export default AddEditCuttingOrderAPI;
